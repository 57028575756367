@font-face {
  font-family: 'Manrope';
  font-display: swap;
  src: url('./fonts/Manrope-VariableFont_wght.ttf') format('truetype-variations');
  font-weight: 1 999;
}

main {
  min-height: 60vh;
}

header {
  height: 270.44px;
  @media (max-width: 1920px) {
    height: 170.44px;
  }
  @media (max-width: 1440px) {
    height: 150.44px;
  }
  @media (max-width: 980px) {
    height: 88px;
  }
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
body::-webkit-scrollbar {
  background-color: transparent;
  width: 6px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

.desktop-only {
  display: none;
}

.mobile-only {
  display: none;
}

/* Показываем десктопную навигацию только на экранах шире 960px */
@media (min-width: 960px) {
  .desktop-only {
    display: flex;
  }
}

/* Показываем мобильное меню только на экранах уже 960px */
@media (max-width: 959px) {
  .mobile-only {
    display: flex;
  }
}

figure.table {
  display: block;
  width: 100%;
  padding: 8px 30px;
  background: rgba(255, 255, 255, 0.1);
  table {
    width: 100%;
    border-collapse: collapse;
    tbody {
      tr {
        &:not(:first-of-type) {
          td {
            border-top: 3px solid rgba(255, 255, 255, 0.05);
          }
        }
        td {
          padding-top: 23px;
          padding-bottom: 23px;
          font-size: 19px;
          text-align: right;
          &:first-of-type {
            text-align: left;
          }
        }
      }
    }
  }
}
